import axios from 'axios';
import { always, isEmpty, values, flatten, zipWith, or, cond, is, T } from 'ramda';
import jws from 'jws';
import { PAYMENT_METHODS } from '../constants';
import { clearEverything, getIdToken } from './auth';
import { encodeJSONObject, notNilNotEmpty } from './helpers';

const API_BASE_URL = process.env.REACT_APP_API_URL || '/api';
const TOKEN_TYPE = 'Bearer';
const METHODS = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
};
const ERROR_STATUS_CODES = ['404', '422', '409'];
const BY_PASS_PATHS = ['/users/', '/group/'];
const CONCURRENCY_MSG = `Changes have been made to this page since it was last opened.
                         Refresh the page to see updated information.`;
/**
 * Takes an object with filters and Storing Value
 * returns a concatinated string in the form of query params
 * including filters and sort
 * @param {Object} filters object containng filters
 * @param {String} sortingValue
 */
const buildFiltersAndSort = ({ filters, sortingValue }) => {
  const singleJoinner = (k, v) => `filter[${k}]=${v}`;
  const joinner = (k, v) =>
    cond([
      [is(Array), iv => iv.map(sv => singleJoinner(k, sv)).join('&')],
      [is(Object), iv => zipWith(singleJoinner, Object.keys(iv), values(iv)).join('&')],
      [T, val => singleJoinner(k, val)]
    ])(v);

  const params = zipWith(joinner, Object.keys(filters), values(filters));

  return `${flatten(params)
    .concat(sortingValue ? [`sort=${sortingValue}`] : [])
    .join('&')}`;
};

const getOffset = (page, limit) => (page ? (page - 1) * limit : null);

const URIS = {
  LOGOUT: always('/authn/logout-global'),
  GET_USER_PROFILE: () => '/me',
  BULK_EMAIL_USERS: () => '/services/bulk-email-users',
  CHANGE_PASSWORD: ({ userId }) => `users/${userId}/change-password`,
  CHANGE_EMAIL: ({ userId }) => `users/${userId}/change-email`,
  RESEND_EMAIL: ({ userId }) => `users/${userId}/change-email-resend`,
  USERS: ({ userId = '' }) => `/users/${userId}`,
  USER_CONTACTS: ({ userId = '' }) => `/users/${userId}/contacts`,
  USER_ADDRESSES: ({ userId = '' }) => `/users/${userId}/addresses`,
  PAYEE_ACCOUNT: ({ id }) => `/payee/${id}`,
  ADD_PAYEE_ACCOUNT: () => `/payee`,
  EDIT_PAYEE_ACCOUNT: ({ id }) => `/payee/${id}`,
  DELETE_USER_ADDRESS: ({ userId, id }) => `/users/${userId}/addresses/${id}`,
  CREATE_NEW_ADMIN: always('/admin/users'),
  USER_CERTIFICATIONS: ({ userId }) => `users/${userId}/certifications`,
  BATCH_PAY: ({ payeeId }) => `/payee/${payeeId}/fetch-transfers`,
  ACCOUNT_BALANCE: ({ payeeId }) => `/payee/${payeeId}/account-balance`,
  PAYMENT_HISTORY: ({ payeeId }) => `/payee/${payeeId}/payout/history`,
  REQUEST_PAYOUT: () => `payee/payout`,
  EVENTS: ({
    eventId = '',
    section = '',
    filters = {},
    limit,
    offset,
    sortingValue = '',
    toCSV = false,
    userOnly = false
  }) => {
    let endpoint = '/events';

    if (eventId) {
      endpoint += `/${eventId}`;
    }

    if (!isEmpty(section)) {
      endpoint += `/${section}`;
    }

    endpoint += '?';

    if (limit)
      endpoint += offset ? `page[limit]=${limit}&page[offset]=${offset}&` : `page[limit]=${limit}&`;

    if (!isEmpty(filters) || sortingValue)
      endpoint += `${buildFiltersAndSort({ filters, sortingValue })}&`;

    if (toCSV) endpoint += 'format=csv&';

    if (userOnly) endpoint += 'filter[user_id]=true&';

    return endpoint.match(/[?&]$/) ? endpoint.slice(0, endpoint.length - 1) : endpoint;
  },
  EVENTSLIST: ({
    eventId = '',
    section = '',
    filters = {},
    limit,
    offset,
    sortingValue = '',
    userOnly = false
  }) => {
    let endpoint = '/events/dropdown/list';

    if (eventId) {
      endpoint += `/${eventId}`;
    }

    if (!isEmpty(section)) {
      endpoint += `/${section}`;
    }

    endpoint += '?';

    if (limit)
      endpoint += offset ? `page[limit]=${limit}&page[offset]=${offset}&` : `page[limit]=${limit}&`;

    if (!isEmpty(filters) || sortingValue)
      endpoint += `${buildFiltersAndSort({ filters, sortingValue })}&`;

    if (userOnly) endpoint += 'filter[user_id]=true&';

    return endpoint.match(/[?&]$/) ? endpoint.slice(0, endpoint.length - 1) : endpoint;
  },

  EVENT: ({ eventId }) => `/events/${eventId}`,
  EVENT_USER_ROLES: ({
    eventId = '',
    roles = [],
    sortingValue = '',
    userid,
    filters = {},
    offset,
    limit,
    toCSV = false
  }) => {
    let endpoint = `/events/${eventId}/roles?${roles.map(id => `filter[role_id]=${id}`).join('&')}`;

    if (limit) endpoint += `&page[limit]=${limit}&page[offset]=${offset}`;

    const encodedFilter = encodeJSONObject(filters);

    endpoint = `${endpoint}${
      or(!isEmpty(encodedFilter), !isEmpty(sortingValue))
        ? `&${buildFiltersAndSort({ filters: encodedFilter, sortingValue })}`
        : ''
    }`;

    if (toCSV) endpoint += '&format=csv';
    if (userid) endpoint += `&userid=${userid}`;

    return endpoint;
  },
  EVENT_PEOPLE_ROLES: ({
    eventId = '',
    roles = [],
    sortingValue = '',
    userid,
    filters = {},
    offset,
    limit,
    toCSV = false
  }) => {
    let endpoint = `/events/${eventId}/eventUserRoles?${roles
      .map(id => `filter[role_id]=${id}`)
      .join('&')}`;

    if (limit)
      endpoint += offset ? `&page[limit]=${limit}&page[offset]=${offset}` : `&page[limit]=${limit}`;

    endpoint = `${endpoint}${
      or(!isEmpty(filters), !isEmpty(sortingValue))
        ? `&${buildFiltersAndSort({ filters, sortingValue })}`
        : ''
    }`;

    if (toCSV) endpoint += '&format=csv';
    if (userid) endpoint += `&userid=${userid}`;

    return endpoint;
  },
  GOVERNINGBODIES: () => `/events/governing-bodies`,
  SPORTS: () => `/sports`,
  CATEGORIES: ({ id }) => `/categories/${id}`,
  GAME_LEVELS: ({ id }) => `/game-levels/${id}`,
  ASSIGNOR_PAY: ({ id }) => `/game-levels/${id}/assignor-pay`,
  TRAVEL_PAY: ({ id }) => `/game-levels/${id}/travel-pay`,
  CREW_PAY: ({ id }) => `/game-levels/${id}/crew-pay`,
  CHECK_CREW_PAY: ({ id }) => `/game-levels/${id}/check-crew-pay`,
  ROLES: () => `/roles`,
  FUNDING_ADMINS: ({ id }) => `/events/${id}/fundingAdmins`,
  USER_IMPORT: ({ id }) => `/events/${id}/roles`,
  USER_MANUALLY: ({ id }) => `/events/${id}/add-new-role`,
  REFRESH_TOKEN: () => `/authn/refresh-tokens`,
  EVENT_ROLES: ({ id, userIds }) => `/events/${id}/roles?ids=${userIds}`,
  EVENT_GAME_LEVELS: ({ id, filters = {} }) => {
    const params = [];
    const endpoint = `/events/${id}/game-levels`;
    if (!isEmpty(filters)) params.push(buildFiltersAndSort({ filters }));
    return isEmpty(params) ? endpoint : `${endpoint}?${params.join('&')}`;
  },
  MONTH_SUMMARY: ({ id }) => `events/${id}/batch-pay-summaries`,
  EVENT_CATEGORIES: ({ id }) => `/events/${id}/categories`,
  BATCH_STAGING_SUMMARY: ({ id }) => `/batch-pay-staging/${id}/summary`,
  BATCH_STAGING_SUMMARY_DETAIL: ({ id }) => `/batch-pay-staging/${id}/summary-detail`,
  BULK_GAME_ASSIGNMENTS_IN_BATCH: () => `/batch-pay-staging/bulk-update-assignments`,
  BATCH_CONFIRM: ({ id }) => `/events/${id}/batch-pay`,
  GAMES: ({ eventId, filters = {}, limit, offset, sortingValue = '', toCSV = false, search }) => {
    let endpoint = eventId ? `/events/${eventId}/games` : `/games`;
    const params = [];

    if (!toCSV && limit) {
      params.push(offset ? `page[limit]=${limit}&page[offset]=${offset}` : `page[limit]=${limit}`);
    }

    if (!isEmpty(filters) || sortingValue) {
      params.push(buildFiltersAndSort({ filters, sortingValue }));
    }

    if (toCSV) {
      params.push('format=csv');
    }

    if (search) {
      params.push(`filter[game.id]=${search}`);
    }
    endpoint = isEmpty(params) ? endpoint : `${endpoint}?${params.join('&')}`;

    return endpoint;
  },
  GAME: ({ id }) => `/games/${id}`,
  GAMES_IMPORT: ({ id }) => `/events/${id}/games`,
  GAMES_UPDATE: ({ id }) => `/games/${id}`,
  GAME_ASSIGNOR: ({ id }) => `/games/${id}/assignment-primary`,
  GAME_ASSIGNOR_UPDATE: ({ id }) => `/game-assignment-primary/${id}`,
  GAME_ASSIGNOR_BULK: ({ id: eventId, ids }) => {
    let endpoint = `events/${eventId}/game-assignment-primary`;
    endpoint = ids ? `${endpoint}?ids=${ids.join(',')}` : endpoint;
    return endpoint;
  },
  BULK_DELETE_GAMES: `/games/bulk-delete`,
  GAME_ASSIGNMENTS: ({ id, ids }) =>
    `/games/${id}/assignments${ids ? `?ids=${ids.join(',')}` : ''}`,
  GAME_ASSIGNMENTS_NEW: ({ id }) => `/games/${id}/new/assignments`,
  ASSIGNMENTS: ({ id }) => `/game-assignments/${id}`,
  ADJUSTMENT_NEW: ({ id }) => `/game-assignments/${id}/adjustments`,
  ADJUSTMENT_UPDATE: ({ id }) => `/game-assignment-adjustments/${id}`,
  GAME_INCIDENT_REPORTS: ({ id, filters = {} }) => {
    const params = [];
    const endpoint = `/games/${id}/incident-reports`;
    if (!isEmpty(filters)) params.push(buildFiltersAndSort({ filters }));

    return isEmpty(params) ? endpoint : `${endpoint}?${params.join('&')}`;
  },
  GAME_CREW_VERIFICATION_REPORTS: ({ id }) => `/games/${id}/crew-verification-reports`,
  GAME_CREW_VERIFICATION_NOTES: ({ id }) => `/games/${id}/crew-verification-notes`,

  GAME_GAME_REPORTS: ({ id, filters = {} }) => {
    const params = [];
    const endpoint = `/games/${id}/game-reports`;
    if (!isEmpty(filters)) params.push(buildFiltersAndSort({ filters }));

    return isEmpty(params) ? endpoint : `${endpoint}?${params.join('&')}`;
  },
  GAME_REPORT_ATTACHMENTS: ({ id }) => {
    const endpoint = `/game-reports/${id}/game-report-attachments`;
    return endpoint;
  },
  INCIDENT_REPORTS: ({ id }) => `/incident-reports/${id}`,
  GAME_REPORTS: ({ id }) => `/game-reports/${id}`,
  EVENT_COMPLEXES: ({ id }) => `/events/${id}/complex/unique`,
  EVENT_LOCATIONS: ({ id, filters = {}, limit, offset, sortingValue = '' }) => {
    let endpoint = `/events/${id}/locations`;

    const params = [];

    if (limit) {
      params.push(offset ? `page[limit]=${limit}&page[offset]=${offset}` : `page[limit]=${limit}`);
    }

    if (!isEmpty(filters) || sortingValue) {
      params.push(buildFiltersAndSort({ filters, sortingValue }));
    }

    endpoint = isEmpty(params) ? endpoint : `${endpoint}?${params.join('&')}`;

    return endpoint;
  },
  EVENT_UNIQUE_TEAMS: ({ id }) => `/events/${id}/teams/unique`,
  EVENT_TEAMS: ({ id, filters = {}, limit, offset, sortingValue = '' }) => {
    let endpoint = `/events/${id}/teams`;

    const params = [];

    if (limit) {
      params.push(offset ? `page[limit]=${limit}&page[offset]=${offset}` : `page[limit]=${limit}`);
    }

    if (!isEmpty(filters) || sortingValue) {
      params.push(buildFiltersAndSort({ filters, sortingValue }));
    }

    endpoint = isEmpty(params) ? endpoint : `${endpoint}?${params.join('&')}`;

    return endpoint;
  },
  EVENT_CREW_LABELS: ({ id }) => `/events/${id}/official-labels`,
  EVENT_ROLES_CATEGORIES: ({ eventRoleId, categoriesRoleIds }) =>
    `/event-roles/${eventRoleId}/categories${
      notNilNotEmpty(categoriesRoleIds) ? '' : `?ids=${categoriesRoleIds.join(',')}`
    }`,
  EVENT_ROLES_CATEGORIES_BULK: () => '/event-roles/categories',
  GAMES_ROLES_CATEGORIES: ({ gameId, gameRoleIds }) =>
    `/games/${gameId}/categories${
      notNilNotEmpty(gameRoleIds) ? '' : `?ids=${gameRoleIds.join(',')}`
    }`,
  INVITATION: ({ id }) => `/invitations/${id}`,
  USER_ASSIGNMENTS: ({ id, start_date, end_date, payment }) =>
    `/users/${id}/game-assignments?filter[start_date]=${start_date}&filter[end_date]=${end_date}${
      payment ? `&include[payment]=${payment}` : ''
    }`,
  USER_SELF_ASSIGNMENTS_SUMMARY: ({ userId, filters, sortingValue, timezone, games_for_date }) => {
    const endpoint = `/games/${userId}/self-assign-games-summary`;
    const params = [];

    const encodedFilter = encodeJSONObject(filters);

    if (!isEmpty(encodedFilter)) {
      params.push(buildFiltersAndSort({ filters: encodedFilter, sortingValue }));
    }

    if (timezone) {
      params.push(`timezone=${timezone}`);
    }

    if (games_for_date) {
      params.push(`games_for_date=${games_for_date}`);
    }
    return isEmpty(params) ? endpoint : `${endpoint}?${params.join('&')}`;
  },
  USER_EVENT_SETTINGS: ({ id }) => `/users/${id}/event-settings`,
  ACTIVE_USER_INFO: ({ userId }) => `/users/${userId}/user-info`,
  AVAILABILITIES: ({ id, user_id }) => {
    let endpoint = '/availabilities';

    if (id) endpoint += `/${id}`;
    if (user_id) endpoint += `?filter[user_id]=${user_id}`;

    return endpoint;
  },
  EVENT_USERS: ({ id }) => `events/${id}/users`,
  EVENT_USERS_BY_USER_ID: ({ id, user_id }) => `events/${id}/users/${user_id}`,
  NOTIFICATIONS: ({ filters }) => {
    const params = [];
    const endpoint = '/notifications';

    if (filters.user_id) params.push(`filter[user_id]=${filters.user_id}`);
    if (filters.alert_type) params.push(`filter[alert_type]=${filters.alert_type}`);
    if (filters.status) params.push(`filter[status]=${filters.status}`);

    return `${endpoint}?${params.join('&')}`;
  },
  NOTIFICATION: ({ id }) => `/notifications/${id}`,
  EVENT_INCIDENT_REPORTS: ({ id, filters = {}, limit, offset, sortingValue }) => {
    const params = [];
    const endpoint = `/events/${id}/incident-reports`;

    if (limit) params.push(`page[limit]=${limit}`);
    if (offset) params.push(`page[offset]=${offset}`);
    if (!isEmpty(filters) || !isEmpty(sortingValue)) {
      params.push(buildFiltersAndSort({ filters, sortingValue }));
    }

    return isEmpty(params) ? endpoint : `${endpoint}?${params.join('&')}`;
  },
  EVENT_ASSESSMENT_REPORTS: ({ id, filters = {}, limit, offset, sortingValue }) => {
    const params = [];
    const endpoint = `/events/${id}/assessment-reports`;

    if (limit) params.push(`page[limit]=${limit}`);
    if (offset) params.push(`page[offset]=${offset}`);
    if (!isEmpty(filters) || !isEmpty(sortingValue)) {
      params.push(buildFiltersAndSort({ filters, sortingValue }));
    }

    return isEmpty(params) ? endpoint : `${endpoint}?${params.join('&')}`;
  },
  ASSESSMENT_REPORT: ({ id }) => `/assessment-reports/${id}`,
  BULK_INCIDENT_REPORTS: ({ eventId, ids }) =>
    `/events/${eventId}/incident-reports?ids=${ids.join(',')}`,
  BULK_ASSESSMENT_REPORTS: ({ eventId, ids }) =>
    `/events/${eventId}/assessment-reports?ids=${ids.join(',')}`,
  BULK_GAME_REPORTS: ({ eventId, ids }) => `/events/${eventId}/game-reports?ids=${ids.join(',')}`,
  EVENT_BATCH: ({ id }) => `/events/${id}/batch-pay-staging`,
  BATCH: ({ id }) => `/batch-pay-staging/${id}`,
  BATCH_GAMES: ({ id, filters, limit, offset, sort, reviewMode }) => {
    const endpoint = reviewMode ? `/batch-pay/${id}/games` : `/batch-pay-staging/${id}/games`;
    const params = [];

    if (limit) params.push(`page[limit]=${limit}`);
    if (offset) params.push(`page[offset]=${offset}`);
    if (!isEmpty(filters) || !isEmpty(sort)) {
      params.push(buildFiltersAndSort({ filters, sortingValue: sort }));
    }

    return isEmpty(params) ? endpoint : `${endpoint}?${params.join('&')}`;
  },
  BATCH_PAY_GAMES_INFO: ({ id, filters = {}, sortingValue = '', toCSV, reviewMode }) => {
    let endpoint = `/batch-pay/${id}/batchPayGamesInfo`;
    const params = [];

    if (reviewMode) {
      endpoint = `/batch-pay/${id}/fundingPayGamesInfo`;
    }

    if (!isEmpty(filters) || sortingValue) {
      params.push(buildFiltersAndSort({ filters, sortingValue }));
    }

    if (toCSV) {
      params.push('format=csv');
    }

    return isEmpty(params) ? endpoint : `${endpoint}?${params.join('&')}`;
  },
  ASSIGNMENT_IN_BATCH: ({ id }) => `/game-assignments/${id}/batch-pay-staging`,
  FULL_GAME_ASSIGNMENTS_IN_BATCH: ({ id }) => `/games/${id}/batch-pay-assignments-staging`,
  EVENT_BATCHES: ({ id, startDate, endDate, toCSV, limit, offset, sort }) => {
    const endpoint = id ? `/events/${id}/batch-pay` : '/events/batch-pay';
    const params = [];
    if (limit) {
      params.push(offset ? `page[limit]=${limit}&page[offset]=${offset}` : `page[limit]=${limit}`);
    }
    if (sort) {
      params.push(`sort=${sort}`);
    }
    if (toCSV) {
      params.push('format=csv');

      if (startDate || endDate) {
        params.push(`filter[start_date]=${startDate}`);
        params.push(`filter[end_date]=${endDate}`);
      }
    }

    return isEmpty(params) ? endpoint : `${endpoint}?${params.join('&')}`;
  },
  BALANCE: ({ id }) => `/events/${id}/get-balance`,
  BATCH_INFO: ({ id }) => `/batch-pay/${id}`,
  ADD_RANK: () => `/event-roles`,
  EVENT_GAME_REPORTS: ({ id, filters = {}, sort = '', limit, offset, byPlayer }) => {
    const params = [];
    const endpoint = `/events/${id}/game-reports`;

    if (limit) params.push(`page[limit]=${limit}`);
    if (offset) params.push(`page[offset]=${offset}`);
    if (!isEmpty(filters) || sort) {
      params.push(buildFiltersAndSort({ filters, sortingValue: sort }));
    }
    if (byPlayer) {
      params.push(`byPlayer=${byPlayer}`);
    }

    return isEmpty(params) ? endpoint : `${endpoint}?${params.join('&')}`;
  },
  ADD_PAYMENT_SOURCE: () => '/payer/payment-source',
  PROCESS_BATCH_FUND: (batchId, sourceId) =>
    sourceId === PAYMENT_METHODS.WALLET
      ? `/batch-pay/${batchId}/payment/wallet`
      : `/batch-pay/${batchId}/payment/source/${sourceId}`,
  FETCH_FUND_SUMMARY: batchId => `/batch-pay/${batchId}/fund-summary-details`,
  FETCH_BATCH_FAILED_HISTORY: batchId => `/batch-pay/${batchId}/failed-history`,
  DELETE_PAYMENT_SOURCE: id => `/payer/payment-source/${id}`,
  FETCH_BATCH_PAYMENT_FEE: ({ batchId, paymentSourceId }) =>
    paymentSourceId === PAYMENT_METHODS.WALLET
      ? `/batch-pay/${batchId}/payment/wallet/transaction-fee`
      : `/batch-pay/${batchId}/payment/source/${paymentSourceId}/transaction-fee`,
  FETCH_PAYOUT_FEE: payeeId => `/payee/${payeeId}/payout/fee`,
  PAYMENT_LOG: () => `/log/payment`,
  APPLICATION_LOG: () => `/log/application-log`,
  FETCH_AGREEMENT: locId => `/doc/location/${locId}`,
  FETCH_PAYMENT_METHODS: (payerId, userId) =>
    userId
      ? `/payer/${payerId}/payment-source?activeUser=${userId}`
      : `/payer/${payerId}/payment-source`,
  GROUPS: id => (id ? `/group/${id}` : '/group'),
  GROUPS_REPORT: () => `/group/groups/report`,
  FETCH_TEAM_LOCATION_COUNTS: subscriptionId => `/events/${subscriptionId}/teams-locations`,
  FETCH_TIMEZONES_LIST: () => `/timezone`,
  FETCH_USER_TAX_INFO: ({ user_id }) => `/users/${user_id}/tax-info`,
  ADD_USER_TAX_INFO: ({ user_id }) => `/users/${user_id}/tax-info`,
  UPDATE_USER_TAX_INFO: ({ user_id, id }) => `/users/${user_id}/tax-info/${id}`,
  FETCH_USER_PROFILE_META: ({ user_id }) => `/users/${user_id}/profile-meta`,
  FETCH_EVENT_BILLING_STATEMENT: ({ startDate, endDate, status, platformId }) => {
    const params = [`status=${status}`];
    const endpoint = `/group/${platformId}/invoice`;

    if (startDate) params.push(`startDate=${startDate}`);
    if (endDate) params.push(`endDate=${endDate}`);

    return `${endpoint}?${params.join('&')}`;
  },
  FETCH_INVOICE_BY_PRODUCT: ({ startDate, endDate, status, platformId, product }) => {
    const params = [`status=${status}`];
    const endpoint = `/group/${platformId}/invoice/product/${product}`;

    if (startDate) params.push(`startDate=${startDate}`);
    if (endDate) params.push(`endDate=${endDate}`);

    return `${endpoint}?${params.join('&')}`;
  },
  PLATFORM_SUBSCRIPTION: ({ platformId }) => `/group/${platformId}/subscription`,
  DELETE_USER_ROLE: ({ userId, roleId }) => `/users/${userId}/role/${roleId}`,
  PRODUCT: ({ id }) => (id ? `/order-product/${id}` : `/order-product`),
  FETCH_GAMES_COUNT: ({ eventId, filters = {} }) => {
    const endpoint = `/events/${eventId}/games-count`;
    const params = [];

    if (!isEmpty(filters)) {
      params.push(buildFiltersAndSort({ filters }));
    }

    return isEmpty(params) ? endpoint : `${endpoint}?${params.join('&')}`;
  },
  FETCH_LOCATIONS_COUNT: ({ eventId, filters = {} }) => {
    const endpoint = `/events/${eventId}/locations-count`;
    const params = [];

    if (!isEmpty(filters)) {
      params.push(buildFiltersAndSort({ filters }));
    }

    return isEmpty(params) ? endpoint : `${endpoint}?${params.join('&')}`;
  },
  FETCH_BATCH_PAY_REPORT: () => '/batch-pay/report',
  GAME_LOCATION: ({ id }) => `games/${id}/location`,
  FETCH_INVOICE_REPORT: () => 'invoice/report',
  FETCH_USER_SCHEDULE: ({ userId, filters = {} }) => {
    const endpoint = `/users/${userId}/user-schedule`;

    const params = [];
    if (!isEmpty(filters)) {
      params.push(buildFiltersAndSort({ filters }));
    }

    return isEmpty(params) ? endpoint : `${endpoint}?${params.join('&')}`;
  },
  FETCH_USER_SELF_ASSIGN_GAMES: ({
    userId,
    filters,
    sortingValue,
    supress_event_data,
    games_for_date
  }) => {
    const endpoint = `/games/${userId}/self-assign-games`;

    const params = [];
    const encodedFilter = encodeJSONObject(filters);
    if (!isEmpty(encodedFilter)) {
      params.push(buildFiltersAndSort({ filters: encodedFilter, sortingValue }));
    }

    if (supress_event_data) {
      params.push(`supress_event_data=${supress_event_data}`);
    }

    if (games_for_date) {
      params.push(`games_for_date=${games_for_date}`);
    }

    return isEmpty(params) ? endpoint : `${endpoint}?${params.join('&')}`;
  },
  ADD_COPIED_GAME_ID: ({ id }) => `/games/${id}/clone`,
  FETCH_ASSIGNMENT_NOTE: ({ id }) => `game-assignments/${id}/note`,
  FETCH_PAYMENT_METHOD_SECRET: ({ payerId, method }) =>
    payerId
      ? `payer/${payerId}/payment-source/token/${method}`
      : `payer/payment-source/token/${method}`,
  CONFIRM_PAYMENT_METHOD: ({ payerId, method }) =>
    payerId
      ? `payer/${payerId}/payment-source/confirm/${method}`
      : `payer/payment-source/confirm/${method}`,
  FETCH_AVAILABILITY_NOTE: ({ id }) => `availabilities/${id}/note`,
  FETCH_UPCOMING_GAMES: ({ userId, filters }) => {
    const endpoint = `/games/${userId}/upcoming-games`;

    const params = [];
    if (!isEmpty(filters)) {
      params.push(buildFiltersAndSort({ filters }));
    }

    return isEmpty(params) ? endpoint : `${endpoint}?${params.join('&')}`;
  },
  PLATFORM_SUBSCRIPTION_HEADER: ({ platformId }) => `/group/${platformId}/subscription/header`,
  CREATE_BILLING_CUSTOMER: ({ platformId }) => `/group/${platformId}/billing/customer`,
  FETCH_BILLING_CUSTOMER: ({ platformId }) => `/group/${platformId}/billing/customers`,
  FETCH_EVENT_BILLING_CUSTOMER: ({ customerType, eventId }) => {
    const params = [`customerType=${customerType}`];
    const endpoint = `/events/${eventId}/customer`;
    return `${endpoint}?${params.join('&')}`;
  },
  CREATE_EVENT_BILLING_CUSTOMER: ({ eventId }) => `/events/${eventId}/customer`,
  FETCH_GROUP_BILLING_CUSTOMER: ({ customerType, platformId }) => {
    const params = [`customerType=${customerType}`];
    const endpoint = `/group/${platformId}/billing/group-customer`;
    return `${endpoint}?${params.join('&')}`;
  },
  UPDATE_BILLING_CUSTOMER: ({ platformId, customerId }) =>
    `/group/${platformId}/billing/customer/${customerId}`,
  FETCH_WALLET: ({ payerId }) => `/payer/${payerId}/wallet`,
  FUND_WALLET: ({ payerId }) => `/payer/${payerId}/wallet/fund`,
  TRANSACTION_HISTORY: ({ payerId, limit }) => {
    let endpoint = `/payer/${payerId}/wallet/transaction`;
    if (limit) {
      endpoint += `?page[limit]=${limit}`;
    }
    return endpoint;
  },
  FETCH_PLATFORM_TRANSACTION_REPORT: ({ startDate, endDate }) => {
    const endpoint = 'invoice/balance-report';
    const params = [];

    if (startDate || endDate) {
      params.push(`filter[startDate]=${startDate}`);
      params.push(`filter[endDate]=${endDate}`);
    }

    return `${endpoint}?${params.join('&')}`;
  }
};

/**
 * Create an Axios Client with defaults
 */
const createClient = token =>
  axios.create({
    baseURL: API_BASE_URL,
    headers: { Authorization: `${TOKEN_TYPE} ${token}` }
  });

const createClientNoAuth = () =>
  axios.create({
    baseURL: API_BASE_URL
  });

// Handle success
const onSuccess = ({ data }) => data;
// Request was made but server responded with something
// or something else happened while setting up the request
const onError = response => {
  try {
    const {
      config: { url },
      data: { status, error_message, errors }
    } = response.response;
    let concurrencyMsg;

    const byPassConcurrency = BY_PASS_PATHS.some(byPassPath => url && url.includes(byPassPath));

    if (!byPassConcurrency && ERROR_STATUS_CODES.includes(status)) {
      concurrencyMsg = CONCURRENCY_MSG;
    }
    const errorMsg = error_message || concurrencyMsg || 'Something went wrong';
    const errorArray = isEmpty(errors) ? [errorMsg] : [...new Set(flatten(values(errors)))];
    const message = errorArray.map(err => ({ type: 'error', message: err }));

    return Promise.reject({ message, status }); // eslint-disable-line
  } catch (error) {
    return Promise.reject();
  }
};

const onErrorUnintercepted = response => {
  const {
    data: { status, error_message: message }
  } = response.response;

  return Promise.reject({ message, status }); // eslint-disable-line
};
/**
 * Request Wrapper with default success/error actions
 */
const request = ({ token = getIdToken(), ...options }) => {
  return createClient(token)(options)
    .then(onSuccess)
    .catch(e => {
      let badToken = false;
      let encounteredError = false;
      try {
        const decoded = jws.decode(token);
        const claims = JSON.parse(decoded.payload);
        const now = Math.floor(new Date().getTime() / 1000);
        const maxClockSkew = 300;
        badToken = now - maxClockSkew > claims.exp || claims.iat > now + maxClockSkew;
      } catch (err) {
        // An error here indicates we are unable to decode the token so something is wrong
        // and the best course of action is to clear everything, logout and reload.
        encounteredError = true;
      }

      if (badToken === true || encounteredError === true) {
        clearEverything();
        setTimeout(() => window.location.reload());
        return Promise.reject();
      }

      return options.errorHandler ? options.errorHandler(e) : onError(e);
    });
};

const requestNoAuth = ({ ...options }) => {
  return createClientNoAuth()(options)
    .then(onSuccess)
    .catch(() => {
      return null;
    });
};

export async function governingbodies() {
  const response = await axios.get(`${API_BASE_URL}/events/governing-bodies`, {
    headers: {
      Authorization: `Bearer ${getIdToken()}`
    }
  });
  return response.data;
}

export default {
  getPayeeAccounts: ({ id }) => {
    return request({
      url: URIS.PAYEE_ACCOUNT({ id }),
      method: METHODS.GET
    });
  },
  addPayeeAccount: ({ data }) =>
    request({
      url: URIS.ADD_PAYEE_ACCOUNT(),
      method: METHODS.POST,
      data
    }),
  editPayeeAccount: ({ id, data }) =>
    request({
      url: URIS.EDIT_PAYEE_ACCOUNT({ id }),
      method: METHODS.PUT,
      data
    }),
  deletePayeeAccount: ({ id }) =>
    request({
      url: URIS.EDIT_PAYEE_ACCOUNT({ id }),
      method: METHODS.DELETE
    }),
  getPaymentHistory: ({ payeeId, month, year }) =>
    request({
      url: `${URIS.PAYMENT_HISTORY({ payeeId })}?month=${month}&year=${year}`,
      method: METHODS.GET
    }),
  getBatchPay: ({ payeeId }) =>
    request({
      url: URIS.BATCH_PAY({ payeeId }),
      method: METHODS.GET
    }),
  getAccountBalance: ({ payeeId }) =>
    request({
      url: URIS.ACCOUNT_BALANCE({ payeeId }),
      method: METHODS.GET
    }),
  requestPayout: ({ data }) =>
    request({
      url: URIS.REQUEST_PAYOUT(),
      method: METHODS.POST,
      data
    }),
  confirmBatch: ({ id, data }) =>
    request({
      url: URIS.BATCH_CONFIRM({ id }),
      method: METHODS.POST,
      data
    }),
  logout: options =>
    request({
      url: URIS.LOGOUT(),
      method: METHODS.POST,
      ...options
    }),
  getUserProfile: ({ ...rest }) =>
    request({
      url: URIS.GET_USER_PROFILE(),
      method: METHODS.GET,
      ...rest
    }),
  changePassword: ({ userId, ...data }) =>
    request({
      url: URIS.CHANGE_PASSWORD({ userId }),
      method: METHODS.POST,
      data
    }),
  changeEmail: ({ userId, ...data }) =>
    request({
      url: URIS.CHANGE_EMAIL({ userId }),
      method: METHODS.POST,
      data
    }),
  resendEmail: ({ userId }) =>
    request({
      url: URIS.RESEND_EMAIL({ userId }),
      method: METHODS.POST
    }),
  updateUser: ({ userId, ...data }) =>
    request({
      url: URIS.USERS({ userId }),
      method: METHODS.PUT,
      data
    }),
  updateUserProfile: ({ userId, ...data }) =>
    request({
      url: URIS.FETCH_USER_PROFILE_META({ user_id: userId }),
      method: METHODS.PUT,
      data
    }),
  fetchUserProfile: ({ userId }) =>
    request({
      url: URIS.USERS({ userId }),
      method: METHODS.GET
    }),
  createUserContacts: ({ userId, ...data }) =>
    request({
      url: URIS.USER_CONTACTS({ userId }),
      method: METHODS.POST,
      data
    }),
  updateUserContacts: ({ userId, id, ...data }) =>
    request({
      url: `${URIS.USER_CONTACTS({ userId })}/${id}`,
      method: METHODS.PUT,
      data
    }),
  deleteUserAddress: ({ userId, id }) =>
    request({
      url: URIS.DELETE_USER_ADDRESS({ userId, id }),
      method: METHODS.DELETE
    }),
  createUserAddresses: ({ userId, ...data }) =>
    request({
      url: URIS.USER_ADDRESSES({ userId }),
      method: METHODS.POST,
      data
    }),
  updateUserAddresses: ({ userId, id, ...data }) =>
    request({
      url: `${URIS.USER_ADDRESSES({ userId })}/${id}`,
      method: METHODS.PUT,
      data
    }),
  createNewUser: data =>
    request({
      url: URIS.USERS({}),
      method: METHODS.POST,
      data
    }),
  createNewAdmin: data =>
    request({
      url: URIS.CREATE_NEW_ADMIN(),
      method: METHODS.POST,
      data
    }),
  fetchEvents: ({ filters, page, limit, sortingValue, toCSV, userOnly }) => {
    const offset = getOffset(page, limit);
    return request({
      url: URIS.EVENTS({ filters, limit, offset, sortingValue, toCSV, userOnly })
    });
  },
  fetchEventList: ({ filters, page, limit, sortingValue, userOnly }) => {
    const offset = getOffset(page, limit);
    return request({
      url: URIS.EVENTSLIST({ filters, limit, offset, sortingValue, userOnly })
    });
  },
  fetchNextEventRolesRelationship: ({ nextLink }) => {
    return request({
      url: nextLink,
      method: METHODS.GET
    });
  },
  fetchEventRolesRelationship: ({
    eventId,
    roles,
    userid,
    filters,
    sortingValue,
    page = 1, // defaulting to zero so that we can get the api next links..
    limit,
    toCSV
  }) => {
    const offset = getOffset(page, limit);
    return request({
      url: URIS.EVENT_USER_ROLES({
        eventId,
        roles,
        filters,
        userid,
        sortingValue,
        limit,
        offset,
        toCSV
      }),
      method: METHODS.GET
    });
  },
  fetchEventRolesPeople: ({
    eventId,
    roles,
    userid,
    filters,
    sortingValue,
    page,
    limit,
    toCSV
  }) => {
    const offset = getOffset(page, limit);
    return request({
      url: URIS.EVENT_PEOPLE_ROLES({
        eventId,
        roles,
        filters,
        userid,
        sortingValue,
        limit,
        offset,
        toCSV
      }),
      method: METHODS.GET
    });
  },
  fetchUserAddresses: ({ userId }) =>
    request({
      url: URIS.USER_ADDRESSES({ userId }),
      method: METHODS.GET
    }),
  fetchUserCertifications: ({ userId }) =>
    request({
      url: URIS.USER_CERTIFICATIONS({ userId }),
      method: METHODS.GET
    }),
  fetchUserContacts: ({ userId }) =>
    request({
      url: URIS.USER_CONTACTS({ userId }),
      method: METHODS.GET
    }),
  fetchMonthSummaryData: ({ id }) =>
    request({
      url: URIS.MONTH_SUMMARY({ id }),
      method: METHODS.GET
    }),
  getEvent: ({ eventId, section = '' }) =>
    request({
      url: URIS.EVENTS({ eventId, section }),
      method: METHODS.GET
    }),
  updateEvent: ({ eventId, section = '', data }) =>
    request({
      url: URIS.EVENTS({ eventId, section }),
      method: METHODS.PUT,
      data
    }),
  createEventCategory: ({ eventId, section = 'categories', data }) =>
    request({
      url: URIS.EVENTS({ eventId, section }),
      method: METHODS.POST,
      data
    }),
  createOrUpdateReport: ({ eventId, section = 'game-report-settings', data }) =>
    request({
      url: URIS.EVENTS({ eventId, section }),
      method: METHODS.POST,
      data
    }),
  createOrUpdateAssessment: ({ eventId, section = 'assessment-report-settings', data }) =>
    request({
      url: URIS.EVENTS({ eventId, section }),
      method: METHODS.POST,
      data
    }),
  createEvent: ({ eventId, section = '', data }) =>
    request({
      url: URIS.EVENTS({ eventId, section }),
      method: METHODS.POST,
      data
    }),
  deleteEvent: ({ eventId }) =>
    request({
      url: URIS.EVENT({ eventId }),
      method: METHODS.DELETE
    }),
  getSports: () =>
    request({
      url: URIS.SPORTS(),
      method: METHODS.GET
    }),
  getEventCategories: ({ id }) =>
    request({
      url: URIS.EVENT_CATEGORIES({ id }),
      method: METHODS.GET
    }),
  deleteCategory: ({ id }) =>
    request({
      url: URIS.CATEGORIES({ id }),
      method: METHODS.DELETE
    }),
  updateCategory: ({ id, data }) =>
    request({
      url: URIS.CATEGORIES({ id }),
      method: METHODS.PUT,
      data
    }),
  getEventGameLevels: ({ id, filters }) =>
    request({
      url: URIS.EVENT_GAME_LEVELS({ id, filters }),
      method: METHODS.GET
    }),
  updateGameLevels: ({ id, data }) =>
    request({
      url: URIS.GAME_LEVELS({ id }),
      method: METHODS.PUT,
      data
    }),
  createGameAssignor: ({ id, data }) =>
    request({
      url: URIS.GAME_ASSIGNOR({ id }),
      method: METHODS.POST,
      data
    }),
  updateGameAssignor: ({ id, data }) =>
    request({
      url: URIS.GAME_ASSIGNOR_UPDATE({ id }),
      method: METHODS.PUT,
      data
    }),
  deleteGameAssignor: ({ id }) =>
    request({
      url: URIS.GAME_ASSIGNOR_UPDATE({ id }),
      method: METHODS.DELETE
    }),
  bulkDeleteGameAssignors: ({ id, ids }) =>
    request({
      url: URIS.GAME_ASSIGNOR_BULK({ id, ids }),
      method: METHODS.DELETE
    }),
  bulkCreateGameAssignors: ({ id, data }) =>
    request({
      url: URIS.GAME_ASSIGNOR_BULK({ id }),
      method: METHODS.POST,
      data
    }),
  bulkUpdateGameAssignors: ({ id, data }) =>
    request({
      url: URIS.GAME_ASSIGNOR_BULK({ id }),
      method: METHODS.PUT,
      data
    }),
  getGameLevel: ({ id, data }) =>
    request({
      url: URIS.GAME_LEVELS({ id }),
      method: METHODS.GET,
      data
    }),
  updateAssignorPay: ({ id, data }) =>
    request({
      url: URIS.ASSIGNOR_PAY({ id }),
      method: METHODS.POST,
      data
    }),
  updateTravelPay: ({ id, data }) =>
    request({
      url: URIS.TRAVEL_PAY({ id }),
      method: METHODS.POST,
      data
    }),
  updateCrewPay: ({ id, data }) =>
    request({
      url: URIS.CREW_PAY({ id }),
      method: METHODS.POST,
      data
    }),
  fetchRoles: () =>
    request({
      url: URIS.ROLES(),
      method: METHODS.GET
    }),
  fetchFundingAdmins: ({ id }) =>
    request({
      url: URIS.FUNDING_ADMINS({ id }),
      method: METHODS.GET
    }),
  submitUserImport: ({ id, file }) => {
    const formData = new FormData();
    formData.append('event_roles', file);
    return request({
      url: URIS.USER_IMPORT({ id }),
      method: METHODS.POST,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    });
  },
  submitUserManually: ({ id, user, role_id }) => {
    return request({
      url: URIS.USER_MANUALLY({ id }),
      method: METHODS.POST,
      data: { user, role_id }
    });
  },
  submitGamesImport: ({ id, file }) => {
    const formData = new FormData();
    formData.append('event_games', file);
    return request({
      url: URIS.GAMES_IMPORT({ id }),
      method: METHODS.POST,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    });
  },
  submitGamesUpdate: ({ gameId, data }) =>
    request({
      url: URIS.GAMES_UPDATE({ id: gameId }),
      method: METHODS.PUT,
      data
    }),
  refreshTokens: dataAndInfo =>
    request({
      url: URIS.REFRESH_TOKEN(),
      method: METHODS.POST,
      ...dataAndInfo
    }),
  addEventUsers: ({
    eventId,
    role_id,
    userList,
    allSelected,
    source_event_id,
    source_role_ids
  }) => {
    return request({
      url: URIS.USER_IMPORT({ id: eventId }),
      method: METHODS.POST,
      data: { role_id, userList, all_selected: allSelected, source_event_id, source_role_ids }
    });
  },
  removeEventUsers: ({ eventId, userIds, role_ids }) => {
    return request({
      url: URIS.EVENT_ROLES({ id: eventId, userIds }),
      method: METHODS.DELETE,
      data: { role_ids }
    });
  },
  fetchGames: ({ eventId, filters, page, limit, sortingValue, toCSV, search }) => {
    const offset = getOffset(page, limit);
    return request({
      url: URIS.GAMES({
        eventId,
        filters,
        limit,
        offset,
        sortingValue,
        toCSV,
        search
      }),
      method: METHODS.GET
    });
  },
  fetchGame: ({ id }) =>
    request({
      url: URIS.GAME({ id }),
      method: METHODS.GET
    }),
  updateGame: ({ id, data }) =>
    request({
      url: URIS.GAME({ id }),
      method: METHODS.PUT,
      data
    }),
  deleteBulkGames: ({ data }) =>
    request({
      url: URIS.BULK_DELETE_GAMES,
      method: METHODS.DELETE,
      data
    }),
  createGameAssignment: ({ id, data }) =>
    request({
      url: URIS.GAME_ASSIGNMENTS_NEW({ id }),
      method: METHODS.POST,
      data
    }),
  fetchGameAssignment: ({ id }) =>
    request({
      url: URIS.ASSIGNMENTS({ id }),
      method: METHODS.GET
    }),
  deleteGameAssignments: ({ id, data }) =>
    request({
      url: URIS.ASSIGNMENTS({ id }),
      method: METHODS.DELETE,
      data
    }),
  updateAssignment: ({ id, data }) =>
    request({
      url: URIS.ASSIGNMENTS({ id }),
      method: METHODS.PUT,
      data
    }),
  fetchSelfAssignmentSummary: ({ userId, filters, sortingValue, timezone, games_for_date }) =>
    request({
      url: URIS.USER_SELF_ASSIGNMENTS_SUMMARY({
        userId,
        filters,
        sortingValue,
        timezone,
        games_for_date
      }),
      method: METHODS.GET
    }),
  createAdjustment: ({ id, data }) =>
    request({
      url: URIS.ADJUSTMENT_NEW({ id }),
      method: METHODS.POST,
      data
    }),
  updateAdjustment: ({ id, data }) =>
    request({
      url: URIS.ADJUSTMENT_UPDATE({ id }),
      method: METHODS.PUT,
      data
    }),
  createIncidentReport: ({ id, data }) =>
    request({
      url: URIS.GAME_INCIDENT_REPORTS({ id }),
      method: METHODS.POST,
      data
    }),
  updateIncidentReport: ({ id, data }) =>
    request({
      url: URIS.INCIDENT_REPORTS({ id }),
      method: METHODS.PUT,
      data
    }),
  deleteIncidentReport: ({ id }) =>
    request({
      url: URIS.INCIDENT_REPORTS({ id }),
      method: METHODS.DELETE
    }),
  fetchIncidentReports: ({ id, filters }) =>
    request({
      url: URIS.GAME_INCIDENT_REPORTS({ id, filters }),
      method: METHODS.GET
    }),

  createCrewVerificationReport: ({ id, data }) =>
    request({
      url: URIS.GAME_CREW_VERIFICATION_REPORTS({ id }),
      method: METHODS.POST,
      data
    }),
  fetchCrewVerificationReports: ({ id, filters }) =>
    request({
      url: URIS.GAME_CREW_VERIFICATION_REPORTS({ id, filters }),
      method: METHODS.GET
    }),
  fetchCrewVerificationNotes: ({ id }) =>
    request({
      url: URIS.GAME_CREW_VERIFICATION_NOTES({ id }),
      method: METHODS.GET
    }),
  createGameReport: ({ id, data }) =>
    request({
      url: URIS.GAME_GAME_REPORTS({ id }),
      method: METHODS.POST,
      data
    }),
  submitGameReportAttachment: ({ id, file }) => {
    const formData = new FormData();
    file.forEach(fileData => {
      formData.append('game_report_images', fileData);
    });
    return request({
      url: URIS.GAME_REPORT_ATTACHMENTS({ id }),
      method: METHODS.POST,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    });
  },
  updateGameReport: ({ id, data }) =>
    request({
      url: URIS.GAME_REPORTS({ id }),
      method: METHODS.PUT,
      data
    }),
  deleteGameReport: ({ id }) =>
    request({
      url: URIS.GAME_REPORTS({ id }),
      method: METHODS.DELETE
    }),
  fetchGameReports: ({ id, filters }) =>
    request({
      url: URIS.GAME_GAME_REPORTS({ id, filters }),
      method: METHODS.GET
    }),
  fetchGameReport: ({ id }) =>
    request({
      url: URIS.GAME_REPORTS({ id }),
      method: METHODS.GET
    }),
  sendEmail: ({ data }) =>
    request({
      url: URIS.BULK_EMAIL_USERS(),
      method: METHODS.POST,
      data
    }),
  fetchEventLocations: ({ id, filters, page, limit, sortingValue }) => {
    const offset = getOffset(page, limit);

    return request({
      url: URIS.EVENT_LOCATIONS({ id, filters, limit, offset, sortingValue }),
      method: METHODS.GET
    });
  },
  fetchEventComplexs: ({ id }) =>
    request({
      url: URIS.EVENT_COMPLEXES({ id }),
      method: METHODS.GET
    }),
  fetchEventUniqueTeams: ({ id }) =>
    request({
      url: URIS.EVENT_UNIQUE_TEAMS({ id }),
      method: METHODS.GET
    }),
  fetchEventTeams: ({ id, filters, page, limit, sortingValue }) => {
    const offset = getOffset(page, limit);

    return request({
      url: URIS.EVENT_TEAMS({ id, filters, limit, offset, sortingValue }),
      method: METHODS.GET
    });
  },
  fetchEventCrewLabels: ({ id }) =>
    request({
      url: URIS.EVENT_CREW_LABELS({ id }),
      method: METHODS.GET
    }),
  fetchEventGameLevels: ({ id }) =>
    request({
      url: URIS.EVENT_GAME_LEVELS({ id }),
      method: METHODS.GET
    }),
  assignCategoriesToUsers: ({ eventRoleId, categories }) =>
    request({
      url: URIS.EVENT_ROLES_CATEGORIES({ eventRoleId }),
      method: METHODS.POST,
      data: categories
    }),
  assignCategoriesToUsersBulk: body => {
    const url = URIS.EVENT_ROLES_CATEGORIES_BULK();
    const data = body;
    const method = METHODS.POST;
    return request({ url, method, data });
  },
  deleteEventCategories: ({ eventRoleId, categoriesRoleIds }) =>
    request({
      url: URIS.EVENT_ROLES_CATEGORIES({ eventRoleId, categoriesRoleIds }),
      method: METHODS.DELETE
    }),
  assignCategoriesToGames: ({ gameId, categories }) =>
    request({
      url: URIS.GAMES_ROLES_CATEGORIES({ gameId }),
      method: METHODS.POST,
      data: categories
    }),
  deleteGamesCategories: ({ gameId, gameRoleIds }) =>
    request({
      url: URIS.GAMES_ROLES_CATEGORIES({ gameId, gameRoleIds }),
      method: METHODS.DELETE
    }),
  linkGamesToEvent: ({ id, data }) =>
    request({
      url: URIS.INVITATION({ id }),
      method: METHODS.PUT,
      data
    }),
  fetchUserAssignments: ({ id, start_date, end_date, payment, options }) =>
    request({
      url: URIS.USER_ASSIGNMENTS({ id, start_date, end_date, payment }),
      method: METHODS.GET,
      ...options
    }),
  fetchUserEventSettings: ({ id }) =>
    request({
      url: URIS.USER_EVENT_SETTINGS({ id }),
      method: METHODS.GET
    }),
  fetchActiveUserInfo: ({ userId }) =>
    request({
      url: URIS.ACTIVE_USER_INFO({ userId }),
      method: METHODS.GET
    }),
  fetchAvailabilities: ({ user_id }) =>
    request({
      url: URIS.AVAILABILITIES({ user_id }),
      method: METHODS.GET
    }),
  createAvailability: ({ data }) =>
    request({
      url: URIS.AVAILABILITIES({}),
      method: METHODS.POST,
      data
    }),
  fetchEventUsers: ({ id }) =>
    request({
      url: URIS.EVENT_USERS({ id }),
      method: METHODS.GET
    }),
  fetchEventUsersByUserId: ({ id, user_id }) =>
    request({
      url: URIS.EVENT_USERS_BY_USER_ID({ id, user_id }),
      method: METHODS.GET
    }),
  updateAvailability: ({ id, data }) =>
    request({
      url: URIS.AVAILABILITIES({ id }),
      method: METHODS.PUT,
      data
    }),
  deleteAvailability: ({ id }) =>
    request({
      url: URIS.AVAILABILITIES({ id }),
      method: METHODS.DELETE
    }),
  fetchNotifications: ({ filters }) =>
    request({
      url: URIS.NOTIFICATIONS({ filters }),
      method: METHODS.GET
    }),
  fetchNotification: ({ id }) =>
    request({
      url: URIS.NOTIFICATION({ id }),
      method: METHODS.GET
    }),
  updateNotification: ({ id, data }) =>
    request({
      url: URIS.NOTIFICATION({ id }),
      method: METHODS.PUT,
      data
    }),
  deleteNotification: ({ id, data }) =>
    request({
      url: URIS.NOTIFICATION({ id }),
      method: METHODS.DELETE,
      data
    }),
  fetchEventIncidentReports: ({ id, filters, page, limit, sortingValue }) => {
    const offset = getOffset(page, limit);
    return request({
      url: URIS.EVENT_INCIDENT_REPORTS({ id, filters, limit, offset, sortingValue }),
      method: METHODS.GET
    });
  },
  fetchEventAssessmentReports: ({ id, filters, page, limit, sortingValue }) => {
    const offset = getOffset(page, limit);
    return request({
      url: URIS.EVENT_ASSESSMENT_REPORTS({ id, filters, limit, offset, sortingValue }),
      method: METHODS.GET
    });
  },
  fetchAssessmentReport: ({ id }) =>
    request({
      url: URIS.ASSESSMENT_REPORT({ id }),
      method: METHODS.GET
    }),
  bulkUpdateIncidentReports: ({ eventId, ids, data }) =>
    request({
      url: URIS.BULK_INCIDENT_REPORTS({ eventId, ids }),
      method: METHODS.PUT,
      data
    }),
  bulkUpdateAssessmentReports: ({ eventId, ids, data }) =>
    request({
      url: URIS.BULK_ASSESSMENT_REPORTS({ eventId, ids }),
      method: METHODS.PUT,
      data
    }),
  bulkUpdateGameReports: ({ eventId, ids, data }) =>
    request({
      url: URIS.BULK_GAME_REPORTS({ eventId, ids }),
      method: METHODS.PUT,
      data
    }),
  updateAssessmentReport: ({ id, data }) =>
    request({
      url: URIS.ASSESSMENT_REPORT({ id }),
      method: METHODS.PUT,
      data
    }),
  createBatch: ({ id, data }) =>
    request({
      url: URIS.EVENT_BATCH({ id }),
      method: METHODS.POST,
      data
    }),
  fetchBatch: ({ id }) =>
    request({
      url: URIS.EVENT_BATCH({ id }),
      method: METHODS.GET
    }),
  deleteBatch: ({ id }) =>
    request({
      url: URIS.BATCH({ id }),
      method: METHODS.DELETE
    }),
  deleteBatchPay: ({ id, data }) =>
    request({
      url: URIS.BATCH_INFO({ id }),
      method: METHODS.DELETE,
      data
    }),
  fetchBatchGames: ({ id, filters, page, limit, sort, reviewMode }) =>
    request({
      url: URIS.BATCH_GAMES({
        id,
        filters,
        sort,
        limit,
        offset: getOffset(page, limit),
        reviewMode
      }),
      method: METHODS.GET
    }),
  fetchBatchPayGamesInfo: ({ id, filters, sort, toCSV, reviewMode }) =>
    request({
      url: URIS.BATCH_PAY_GAMES_INFO({
        id,
        filters,
        sort,
        toCSV,
        reviewMode
      }),
      method: METHODS.GET
    }),
  updateAssignmentInBatch: ({ id, data }) =>
    request({
      url: URIS.ASSIGNMENT_IN_BATCH({ id }),
      method: METHODS.PUT,
      data
    }),
  fetchBatchStagingSummary: ({ id }) =>
    request({
      url: URIS.BATCH_STAGING_SUMMARY({ id }),
      method: METHODS.GET
    }),
  fetchBatchStagingSummaryDetails: ({ id }) =>
    request({
      url: URIS.BATCH_STAGING_SUMMARY_DETAIL({ id }),
      method: METHODS.GET
    }),
  updateFullGameAssignmentsInBatch: ({ id, data }) =>
    request({
      url: URIS.FULL_GAME_ASSIGNMENTS_IN_BATCH({ id }),
      method: METHODS.PUT,
      data
    }),
  bulkUpdateGameAssignmentsInBatch: ({ data }) => {
    const requestBody = {
      url: URIS.BULK_GAME_ASSIGNMENTS_IN_BATCH(),
      method: METHODS.POST,
      data
    };
    return request(requestBody);
  },
  fetchEventBatches: ({ id, startDate, endDate, toCSV, page, limit, sort }) =>
    request({
      url: URIS.EVENT_BATCHES({
        id,
        startDate,
        endDate,
        toCSV,
        limit,
        sort,
        offset: getOffset(page, limit)
      }),
      method: METHODS.GET
    }),
  fetchBatchInfo: ({ id }) =>
    request({
      url: URIS.BATCH_INFO({ id }),
      method: METHODS.GET
    }),
  addRank: input =>
    request({
      url: URIS.ADD_RANK(),
      method: METHODS.PUT,
      data: input
    }),
  fetchEventGameReports: ({ id, filters, sort, page, limit, byPlayer }) => {
    const offset = getOffset(page, limit);
    return request({
      url: URIS.EVENT_GAME_REPORTS({ id, filters, sort, limit, offset, byPlayer }),
      method: METHODS.GET
    });
  },
  addPaymentSource: payload =>
    request({
      url: URIS.ADD_PAYMENT_SOURCE(),
      method: METHODS.POST,
      data: payload
    }),
  processBatchFund: payload =>
    request({
      url: URIS.PROCESS_BATCH_FUND(payload.batchId, payload.paymentSource),
      method: METHODS.POST,
      data: payload
    }),
  fetchFundSummary: batchId =>
    request({
      url: URIS.FETCH_FUND_SUMMARY(batchId),
      method: METHODS.GET
    }),
  fetchBatchFailedHistory: batchId =>
    request({
      url: URIS.FETCH_BATCH_FAILED_HISTORY(batchId),
      method: METHODS.GET
    }),
  fetchBatchPaymentFee: ({ batchId, paymentSourceId }) =>
    request({
      url: URIS.FETCH_BATCH_PAYMENT_FEE({ batchId, paymentSourceId }),
      method: METHODS.GET
    }),
  fetchPayoutFee: ({ payeeId, amount }) =>
    request({
      url: URIS.FETCH_PAYOUT_FEE(payeeId),
      method: METHODS.POST,
      data: { amount }
    }),
  deletePaymentSource: payload =>
    request({
      url: URIS.DELETE_PAYMENT_SOURCE(payload.id),
      method: METHODS.DELETE,
      data: payload
    }),
  paymentLog: payload =>
    request({
      url: URIS.PAYMENT_LOG(),
      method: METHODS.POST,
      data: payload
    }),
  applicationLog: payload =>
    requestNoAuth({
      url: URIS.APPLICATION_LOG(),
      method: METHODS.POST,
      data: payload
    }),
  fetchAgreement: payload =>
    request({
      url: URIS.FETCH_AGREEMENT(payload.locId),
      method: METHODS.GET
    }),
  fetchPaymentMethods: payload =>
    request({
      url: URIS.FETCH_PAYMENT_METHODS(payload.payerId, payload.userId),
      method: METHODS.GET
    }),
  fetchGroups: () =>
    request({
      url: URIS.GROUPS(),
      method: METHODS.GET,
      errorHandler: onErrorUnintercepted
    }),

  fetchGroupsReport: () =>
    request({
      url: URIS.GROUPS_REPORT(),
      method: METHODS.GET
    }),

  fetchGoverningBodies: () =>
    request({
      url: URIS.GOVERNINGBODIES(),
      method: METHODS.GET
    }),
  createGroup: ({ ...data }) =>
    request({
      url: URIS.GROUPS(),
      method: METHODS.POST,
      data
    }),
  fetchGroupInfo: ({ id }) =>
    request({
      url: URIS.GROUPS(id),
      method: METHODS.GET
    }),
  updateGroupInfo: ({ id, ...data }) =>
    request({
      url: URIS.GROUPS(id),
      method: METHODS.PUT,
      data
    }),
  fetchTeamLocationCounts: payload =>
    request({
      url: URIS.FETCH_TEAM_LOCATION_COUNTS(payload.subscriptionId),
      method: METHODS.GET
    }),
  fetchCertifications: ({ userId }) =>
    request({
      url: URIS.USER_CERTIFICATIONS({ userId }),
      method: METHODS.GET
    }),
  addCertifications: ({ userId, ...data }) =>
    request({
      url: URIS.USER_CERTIFICATIONS({ userId }),
      method: METHODS.POST,
      data
    }),
  updateCertifications: ({ userId, ...data }) =>
    request({
      url: URIS.USER_CERTIFICATIONS({ userId }),
      method: METHODS.PUT,
      data
    }),
  deleteCertifications: ({ userId, ...data }) =>
    request({
      url: URIS.USER_CERTIFICATIONS({ userId }),
      method: METHODS.DELETE,
      data
    }),
  fetchTimezonesList: ({ ...rest }) =>
    request({
      url: URIS.FETCH_TIMEZONES_LIST(),
      method: METHODS.GET,
      ...rest
    }),
  fetchUserTaxInfo: ({ user_id }) => {
    return request({
      url: URIS.FETCH_USER_TAX_INFO({ user_id })
    });
  },
  fetchUserProfileMeta: ({ user_id }) => {
    return request({
      url: URIS.FETCH_USER_PROFILE_META({ user_id })
    });
  },
  createTaxInfo: ({ data, user_id }) =>
    request({
      url: URIS.ADD_USER_TAX_INFO({ user_id }),
      method: METHODS.POST,
      data
    }),
  updateTaxInfo: ({ data, user_id }) =>
    request({
      url: URIS.UPDATE_USER_TAX_INFO({ id: data.id, user_id }),
      method: METHODS.PUT,
      data
    }),
  fetchEventBillingStatements: ({ startDate, endDate, status, platformId }) => {
    return request({
      url: URIS.FETCH_EVENT_BILLING_STATEMENT({
        startDate,
        endDate,
        status,
        platformId
      }),
      method: METHODS.GET
    });
  },
  fetchProductInvoices: ({ startDate, endDate, status, platformId, product }) => {
    return request({
      url: URIS.FETCH_INVOICE_BY_PRODUCT({
        startDate,
        endDate,
        status,
        platformId,
        product
      }),
      method: METHODS.GET
    });
  },
  fetchPlatformSubscriptions: ({ platformId }) => {
    return request({
      url: URIS.PLATFORM_SUBSCRIPTION({ platformId }),
      method: METHODS.GET
    });
  },
  updatePlatformSubscription: ({ platformId, ...data }) => {
    return request({
      url: URIS.PLATFORM_SUBSCRIPTION({ platformId }),
      method: METHODS.PUT,
      data
    });
  },
  updatePlatformSubscriptionHeader: ({ platformId, ...data }) => {
    return request({
      url: URIS.PLATFORM_SUBSCRIPTION_HEADER({ platformId }),
      method: METHODS.PUT,
      data
    });
  },
  deleteUserRole: ({ userId, roleId, ...data }) =>
    request({
      url: URIS.DELETE_USER_ROLE({ userId, roleId }),
      method: METHODS.DELETE,
      data
    }),
  updateSubscriptionFee: ({ id, ...data }) =>
    request({
      url: URIS.PRODUCT({ id }),
      method: METHODS.PUT,
      data
    }),
  fetchGamesCount: ({ eventId, filters }) =>
    request({
      url: URIS.FETCH_GAMES_COUNT({ eventId, filters }),
      method: METHODS.GET
    }),
  fetchLocationsCount: ({ eventId, filters }) =>
    request({
      url: URIS.FETCH_LOCATIONS_COUNT({ eventId, filters }),
      method: METHODS.GET
    }),
  fetchBatchPayReport: () =>
    request({
      url: URIS.FETCH_BATCH_PAY_REPORT(),
      method: METHODS.GET
    }),
  fetchGameLocation: ({ id }) =>
    request({
      url: URIS.GAME_LOCATION({ id }),
      method: METHODS.GET
    }),

  submitCopiedGameId: ({ gameId, data }) =>
    request({
      url: URIS.ADD_COPIED_GAME_ID({ id: gameId }),
      method: METHODS.POST,
      data
    }),
  fetchInvoiceReport: () =>
    request({
      url: URIS.FETCH_INVOICE_REPORT(),
      method: METHODS.GET
    }),
  fetchUserSchedule: ({ userId, filters }) =>
    request({
      url: URIS.FETCH_USER_SCHEDULE({ userId, filters }),
      method: METHODS.GET
    }),
  fetchUserSelfAssignGames: ({
    userId,
    filters,
    sortingValue,
    supress_event_data,
    games_for_date
  }) =>
    request({
      url: URIS.FETCH_USER_SELF_ASSIGN_GAMES({
        userId,
        filters,
        sortingValue,
        supress_event_data,
        games_for_date
      }),
      method: METHODS.GET
    }),
  fetchAssignmentNote: ({ id }) =>
    request({
      url: URIS.FETCH_ASSIGNMENT_NOTE({ id }),
      method: METHODS.GET
    }),
  fetchPaymentMethodSecret: ({ payerId, method }) =>
    request({
      url: URIS.FETCH_PAYMENT_METHOD_SECRET({ payerId, method }),
      method: METHODS.GET
    }),
  confirmPaymentMethod: ({ payerId, method, data }) =>
    request({
      url: URIS.CONFIRM_PAYMENT_METHOD({ payerId, method }),
      method: METHODS.POST,
      data
    }),
  fetchAvailabilityNote: ({ id }) =>
    request({
      url: URIS.FETCH_AVAILABILITY_NOTE({ id }),
      method: METHODS.GET
    }),
  fetchUpcomingGames: ({ userId, filters }) =>
    request({
      url: URIS.FETCH_UPCOMING_GAMES({ userId, filters }),
      method: METHODS.GET
    }),
  createBillingCustomers: ({ platformId, data }) =>
    request({
      url: URIS.CREATE_BILLING_CUSTOMER({ platformId }),
      method: METHODS.POST,
      data
    }),
  fetchBillingCustomers: ({ platformId }) =>
    request({
      url: URIS.FETCH_BILLING_CUSTOMER({ platformId }),
      method: METHODS.GET
    }),
  fetchEventBillingCustomer: ({ eventId, customerType }) =>
    request({
      url: URIS.FETCH_EVENT_BILLING_CUSTOMER({ eventId, customerType }),
      method: METHODS.GET
    }),
  createEventBillingCustomer: ({ eventId, data }) =>
    request({
      url: URIS.CREATE_EVENT_BILLING_CUSTOMER({ eventId }),
      method: METHODS.POST,
      data
    }),
  fetchGroupBillingCustomer: ({ platformId, customerType }) =>
    request({
      url: URIS.FETCH_GROUP_BILLING_CUSTOMER({ platformId, customerType }),
      method: METHODS.GET
    }),
  updateBillingCustomer: ({ platformId, customerId, data }) =>
    request({
      url: URIS.UPDATE_BILLING_CUSTOMER({ platformId, customerId }),
      method: METHODS.PUT,
      data
    }),
  fetchWallet: ({ payerId }) =>
    request({
      url: URIS.FETCH_WALLET({ payerId }),
      method: METHODS.GET
    }),
  createWalletFund: ({ payerId, data }) =>
    request({
      url: URIS.FUND_WALLET({ payerId }),
      method: METHODS.POST,
      data
    }),
  fetchTransactionHistory: ({ payerId, limit }) =>
    request({
      url: URIS.TRANSACTION_HISTORY({ payerId, limit }),
      method: METHODS.GET
    }),
  fetchPlatformTransactionReport: ({ startDate, endDate }) =>
    request({
      url: URIS.FETCH_PLATFORM_TRANSACTION_REPORT({ startDate, endDate }),
      method: METHODS.GET
    })
};
