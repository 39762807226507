import { compose, withState, withHandlers, pure, withProps, lifecycle } from 'recompose';
import moment from 'moment';
import { equals, isEmpty } from 'ramda';
import MODAL_NAMES, { FROM_CALENDAR, FROM_GAMES } from '../../../core/Modals/constants';
import { DATE_FORMATS, GAME_ASSIGNMENT_STATUSES } from '../../../constants';
import { convertToTimezone, generateRefreshToken, uniqueBy } from '../../../utils/helpers';
import { openExternalGameReportURL } from '../Games/utils';

import { getCalendarFormatGames } from '../../components/Calendar/config';

const DefaultValues = {
  LIMIT: 20,
  SORTBY: 'season_start_date'
};

export default compose(
  withState('showAssessmentReportModal', 'setShowAssessmentReportModal', null),
  withState('showCalendar', 'setShowCalendar', false),
  withState('date', 'setDate', new Date()),
  withState('pulledDates', 'setPulledDates', []),
  withState('showBanner', 'setShowBanner', true),
  withState('activeMonth', 'setActiveMonth', moment().format('MM')),
  withState('calendarFilterGames', 'setCalendarFilterGames', []),
  withProps({
    startAndEndDates: date => {
      const [month, year] = date.format('MM-YYYY').split('-');
      const start_date = moment([year, month - 1]);
      return {
        start_date,
        end_date: moment(start_date).endOf('month')
      };
    }
  }),
  withHandlers({
    userSelfAssignGames: ({ fetchUserSelfAssignGames }) => async ({ userId, start_date }) => {
      if (
        moment().format(DATE_FORMATS.YYYY_MM_DD) <=
        moment(start_date).format(DATE_FORMATS.YYYY_MM_DD)
      ) {
        fetchUserSelfAssignGames({
          userId,
          filters: {
            start_date,
            end_date: moment(start_date).endOf('day').format(),
            official_self_assign_setting: true,
            eventStatus: true
          },
          supress_event_data: true,
          games_for_date: moment(start_date).date(),
          sortingValue: DefaultValues.SORTBY
        });
      }

      return true;
    }
  }),
  withHandlers({
    bannerClose: ({ deleteNotification }) => async notificationsIds => {
      deleteNotification({ id: notificationsIds });
    },
    onAssessmentReportClick: ({
      storeReportsEventAndRedirect,
      setShowAssessmentReportModal,
      setShowModalFor
    }) => ({ eventId, gameId, assignmentOfficial, crewLabel }) => {
      const report = assignmentOfficial && assignmentOfficial.assessment_report;
      if (report && report.approved) {
        setShowAssessmentReportModal({
          crewLabel,
          gameId: assignmentOfficial.external_game_id,
          reportId: report.id
        });
        setShowModalFor({ name: MODAL_NAMES.assessmentReportsModal, from: FROM_CALENDAR });
      } else {
        storeReportsEventAndRedirect({ id: eventId, gameId });
      }
    },
    onGamesReportClick: ({
      setCurrentGame,
      setShowModalFor,
      setCalendarGame,
      userIdx,
      user
    }) => async gameInfo => {
      if (gameInfo.report_url) {
        const token = await generateRefreshToken(userIdx, user);
        openExternalGameReportURL(gameInfo.report_url, token);
        return true;
      }
      if (gameInfo.allow_game_reports) {
        setCurrentGame(gameInfo);
        setCalendarGame(gameInfo);
        setShowModalFor({ name: MODAL_NAMES.gameReportsModal, from: FROM_GAMES });
        return true;
      }
      return true;
    },
    /* START onCalendarMonthChange calls on switching between months */
    onCalendarMonthChange: ({
      user,
      fetchAssignments,
      fetchUserSchedule,
      setActiveMonth,
      setDate,
      fetchSelfAssignmentSummary,
      clearUserSelfAssignedGames,
      clearSummary,
      setPulledDates
    }) => async ({ day }) => {
      const userId = user && user.id;
      const startOfMonth = moment(day).startOf('month').format(DATE_FORMATS.YYYY_MM_DD_HH_mm);
      const endOfMonth = moment(day).endOf('month').format(DATE_FORMATS.YYYY_MM_DD_HH_mm);
      const localTimezone = moment.tz.guess();

      clearUserSelfAssignedGames();
      fetchAssignments({ start_date: startOfMonth, end_date: endOfMonth, payment: true });

      const isCurrentMonth = moment(day).isSame(moment(), 'month');
      const isFutureMonth = moment(day).isAfter(moment(), 'month');
      const isPastMonth = moment(day).isBefore(moment(), 'month');

      let start_date;

      if (isCurrentMonth) {
        start_date = moment().startOf('day').format(DATE_FORMATS.YYYY_MM_DD_HH_mm);
      } else if (isFutureMonth) {
        start_date = moment(day).startOf('day').format(DATE_FORMATS.YYYY_MM_DD_HH_mm);
      } else {
        start_date = moment(day).startOf('month').format(DATE_FORMATS.YYYY_MM_DD_HH_mm);
      }

      fetchUserSchedule({ userId, start_date, end_date: endOfMonth });

      if (
        !isPastMonth &&
        (isCurrentMonth || (isFutureMonth && moment(endOfMonth).isSameOrAfter(moment(), 'day')))
      ) {
        fetchSelfAssignmentSummary({
          userId,
          filters: {
            start_date,
            end_date: endOfMonth
          },
          sortingValue: DefaultValues.SORTBY,
          timezone: localTimezone,
          games_for_date: moment(start_date).date()
        });

        setPulledDates(prevPulledDates => [...prevPulledDates, start_date]);
      } else {
        clearSummary();
      }

      setActiveMonth(moment(day).format('MM'));
      setDate(day);
    },
    /* END onCalendarMonthChange calls on switching between months */
    saveSelfAssignGames: ({ setCalendarFilterGames, activeMonth }) => async selfAssignGames => {
      let filterGame = selfAssignGames.length
        ? getCalendarFormatGames(selfAssignGames, activeMonth)
        : [];
      // remove duplicate games with same game id
      filterGame = uniqueBy(filterGame, game => game.id);
      return setCalendarFilterGames(filterGame);
    }
  }),
  withHandlers({
    createGameAssignment: ({ createAssignment, userSchedule, fetchUserSelfAssignGames }) => async ({
      game,
      user,
      label,
      onLoadPage
    }) => {
      let crewLabelCol;
      game.game_level.labels.filter((value, index) => {
        if (label === value) {
          crewLabelCol = index;
        }
        return null;
      });
      const postionRole = game.game_level.role_ids[crewLabelCol];
      const eventRoleId = userSchedule.event_roles
        .filter(
          roles =>
            roles.event_id === game.game_level.event_id &&
            Number(roles.role_id) === Number(postionRole)
        )
        .map(value => value.id)
        .join();

      createAssignment({
        external_start_date: convertToTimezone(
          `${game.start_date} ${game.start_time}`,
          game.timezone
        ),
        external_end_date: convertToTimezone(`${game.end_date} ${game.end_time}`, game.timezone),
        timezone: game.timezone,
        external_event_id: game.display_id,
        external_location_id: game.location.id,
        eventRoleId,
        game_status: game.status,
        game_published: game.published,
        userId: user.id,
        eventId: game.game_level.event_id,
        game_level_id: game.game_level.id,
        ignore_rules: true,
        crewLabelCol,
        status: GAME_ASSIGNMENT_STATUSES.accepted,
        gameId: game.id,
        onLoadPage,
        reloadSelfAssignmentsForDay: fetchUserSelfAssignGames,
        selfAssignGamesRequestBody: {
          userId: user.id,
          filters: {
            start_date: moment(game.start_at).startOf('day').format(),
            end_date: moment(game.end_at).endOf('day').format(),
            official_self_assign_setting: true,
            eventStatus: true
          },
          supress_event_data: true,
          games_for_date: moment(game.start_at).date(),
          sortingValue: DefaultValues.SORTBY
        }
      });

      return true;
    }
  }),
  lifecycle({
    componentDidMount() {
      const {
        selfAssignGames,
        saveSelfAssignGames,
        fetchAssignments,
        fetchSelfAssignmentSummary,
        fetchUserSchedule,
        fetchEventSettings,
        setShowCalendarAction,
        setPulledDates
      } = this.props;
      const userId = this.props.user && this.props.user.id;
      const chosenDate = this.props.chosenDate || moment();
      const start_date = moment(chosenDate).startOf('day').format(); // removing the utc offset. .. do i really need it?
      const start_date_month = moment(chosenDate).startOf('month').format();
      // add 1 day to the end date to include the last day of the month when timezone is considered
      const end_date_month = moment(chosenDate).endOf('month').add('day', 1).format();
      const localTimezone = moment.tz.guess();
      fetchAssignments({ start_date: start_date_month, end_date: end_date_month, payment: true });
      fetchUserSchedule({ userId, start_date: start_date_month, end_date: end_date_month });
      fetchEventSettings();
      setShowCalendarAction({ show: true, calendarActive: true });

      fetchSelfAssignmentSummary({
        userId,
        filters: {
          start_date,
          end_date: end_date_month
        },
        sortingValue: DefaultValues.SORTBY,
        timezone: localTimezone,
        games_for_date: moment(start_date).date()
      });

      setPulledDates(prevPulledDates => [...prevPulledDates, start_date]);

      if (!isEmpty(selfAssignGames)) {
        saveSelfAssignGames(selfAssignGames);
      }
    },
    componentWillReceiveProps(nextProps) {
      const {
        selfAssignGames: nextSelfAssignGames,
        userSchedule: nextUserSchedule,
        saveSelfAssignGames
      } = nextProps;
      const {
        selfAssignGames: currentSelfAssignGames,
        userSchedule: currentUserSchedule
      } = this.props;
      const userScheduleChange = !equals(nextUserSchedule, currentUserSchedule);
      if (!equals(nextSelfAssignGames, currentSelfAssignGames) || userScheduleChange) {
        saveSelfAssignGames(nextSelfAssignGames);
      }
    },
    componentWillUnmount() {
      const availabilityPage = window.location.href.includes('availability');
      if (!availabilityPage) {
        this.props.setShowCalendarAction({ show: false, calendarActive: false });
      }
      const { unsetShowModalFor } = this.props;
      unsetShowModalFor({});
    }
  }),
  pure
);
