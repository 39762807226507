import React from 'react';
import PropTypes from 'prop-types';
import Flex from '../../Flex';
import Dropdown from '../../Reusable/Dropdown';
import EventFilter from '../../EventFilter';
import { HEIGHTS } from '../../../../utils/styles';
import theme from './styled-components';
import { PAYMENTS_VIEW, CORE_ROLES } from '../../../../constants';

const Header = ({
  storePaymentsEvent,
  clearParams,
  paymentsEvent,
  paymentsViewSelected,
  setPaymentsViewSelected,
  isFundingAdmin,
  isPaymentsEventGroupAdmin,
  isSuperAdmin
}) => (
  <Flex
    bgImage="linear-gradient(to top, #05579d, #167cd4)"
    height={HEIGHTS.eventNavigationBar}
    justifyContent="flex-start"
    padding="0 1.5rem 0 0"
    fullWidth
    noShrink
  >
    <Flex>
      <EventFilter
        onSelectEvent={storePaymentsEvent}
        clearParams={clearParams}
        selectedEvent={paymentsEvent}
        filters={{
          role_id:
            isFundingAdmin && !isPaymentsEventGroupAdmin && !isSuperAdmin
              ? CORE_ROLES.funding_admin
              : CORE_ROLES.admin,
          set_crew_pay: 1
        }}
        paymentsViewSelected={paymentsViewSelected}
      />
    </Flex>
    <Flex theme={theme.dropdownContainer} height={HEIGHTS.eventNavigationBar}>
      <Dropdown
        options={
          isFundingAdmin && !isPaymentsEventGroupAdmin && !isSuperAdmin
            ? [{ name: 'Funding', value: PAYMENTS_VIEW.FUNDING }]
            : [
                { name: 'Game Pay', value: PAYMENTS_VIEW.GAME_PAY },
                { name: 'Funding', value: PAYMENTS_VIEW.FUNDING }
              ]
        }
        input={{
          value: paymentsViewSelected,
          onChange: setPaymentsViewSelected
        }}
        theme={theme.dropdown}
        displayValue
      />
    </Flex>
  </Flex>
);

Header.propTypes = {
  paymentsEvent: PropTypes.shape({
    id: PropTypes.string
  }),
  storePaymentsEvent: PropTypes.func.isRequired,
  clearParams: PropTypes.func,
  paymentsViewSelected: PropTypes.string.isRequired,
  setPaymentsViewSelected: PropTypes.func.isRequired
};

Header.defaultProps = {
  paymentsEvent: null,
  clearParams: () => undefined
};

export default Header;
