import React, { useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { isEmpty, isNil } from 'ramda';
import moment from 'moment';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../../utils/styles';
import ToolTip from '../../../global-material-components/ToolTip';
import MouseOverPopover from '../../../global-material-components/Popover';
import ConfirmationPopup from '../Popups/DeclineAssignmentConfirmation';
import {
  AssignmentRow,
  Time,
  Info,
  FieldInfo,
  GameStatus,
  StatusIconContainer,
  StyledButton,
  ReportLink,
  SubInfo,
  Uppercase,
  ReportTab,
  AssignmentInfoCol,
  AssignmentInfoRow,
  InfoRow,
  CrewName,
  PositionRow,
  Block,
  Content,
  ButtonContainer
} from './styled-components';
import { IconStyle } from '../styled-components';

import MODAL_NAMES, { FROM_GAMES } from '../../../../core/Modals/constants';
import ROLES from '../../../../core/Roles/roleConstants';
import { convertToUtc, addCountToDisplayId } from '../../../../utils/helpers';
import Direction from '../direction';
import { GAMES_STATUSES, GAME_ASSIGNMENT_STATUSES } from '../../../../constants';
import OpenPosition from './OpenPosition';
import AssignedPosition from './AssignedPosition';
import { checkAssignmentConflict } from '../../Games/Modals/AcceptAssignmentsModal/config';
import Modal from '../../Modal';
import Button from '../../Button';
import { ASSIGNMENT_STATUS } from '../../../../core/Games/constants';

const DefaultValues = {
  LIMIT: 20,
  SORTBY: 'season_start_date'
};

const useStyles = makeStyles(() => ({
  Button: { pointerEvents: 'none' },
  CalendarIcon: { marginTop: '0px' },
  FlagIcon: { marginTop: '4px', marginLeft: '-3px' },
  assignorLabel: { whiteSpace: 'nowrap', paddingRight: '2px' },
  crewTooltip: { width: '100%' }
}));

const CalenderExpandInfo = ({
  game,
  updateGameAssignments,
  userSelfAssignGames,
  setCurrentGame,
  setShowModalFor,
  onAssessmentReportClick,
  date,
  fetchAssignments,
  startAndEndDates,
  setCalendarGame,
  onGamesReportClick,
  timezoneAbbr,
  createGameAssignment,
  user,
  userSchedule,
  setShowAcceptErrorAssignmentsModal,
  showAcceptErrorAssignmentsModal,
  hasConflictError,
  setHasConflictError,
  setPulledDates
}) => {
  const [isDeclinedClick, setIsDeclinedClick] = useState(false);
  const { crew, game_level } = game;
  const assignment = game.assignments_game.length && game.assignments_game[0];
  const calendar_game_status = ['updated', 'cancelled', 'postponed'];
  const assessmentReportSettings =
    game &&
    game.game_level &&
    game.game_level.event &&
    game.game_level.event.assessment_report_settings;
  const gameReportSettings =
    game && game.game_level && game.game_level.event && game.game_level.event.report_settings;
  const roleId = assignment && assignment.event_role && assignment.event_role.role_id;
  const userId = assignment && assignment.event_role && assignment.event_role.user_id;
  const classes = useStyles();

  const gameInfo = {
    ...game,
    ...{
      end_date: moment(`${game.end_at}`).format('MM/DD/YYYY'),
      start_date: moment(`${game.start_at}`).format('MM/DD/YYYY'),
      end_time: moment(`${game.end_at}`).format('hh:mm A')
    }
  };

  const crewMembers =
    crew &&
    crew.length > 0 &&
    crew.sort(function (a, b) {
      return a.official_label_col - b.official_label_col;
    });
  const scheduleOwner = game_level &&
    game_level.user && {
      assignor_first_name: game_level.user.first_name,
      assignor_last_name: game_level.user.last_name,
      assignor_phone_number: game_level.user.contact_primary.primary_phone_number
    };

  const assignorInfo = crewMembers && crewMembers.find(member => member.id === userId);
  const assignorDetails =
    isNil(assignorInfo) || isEmpty(assignorInfo) ? scheduleOwner : assignorInfo;
  const onIncidentReportClick = currentGame => {
    setCurrentGame(currentGame);
    setCalendarGame(currentGame);
    setShowModalFor({ name: MODAL_NAMES.incidentReportsModal, from: FROM_GAMES });
  };

  const onCrewVerificationClick = currentGame => {
    setCurrentGame(currentGame);
    setCalendarGame(currentGame);
    setShowModalFor({ name: MODAL_NAMES.crewVerificationReportsModal, from: FROM_GAMES });
  };

  const isGameAssessor = roleId === ROLES.ASSESSOR;
  const gameOfficial = roleId === ROLES.OFFICIAL;

  const gameOfficialAR = gameOfficial && assignment.assessment_report;
  const allowedToSeeReports = gameOfficial || isGameAssessor;

  const allowedForGameOfficialReport = gameOfficial && !isGameAssessor;
  const allowedToSeeReportsForGameReport = isGameAssessor;
  const allowedToSeeIR = gameReportSettings && gameReportSettings.allow_officials_to_submit;
  const allowedToSeeGameReports =
    gameReportSettings &&
    gameReportSettings.score_entry_stats !== 'hidden' &&
    (roleId === ROLES.OFFICIAL || (roleId === ROLES.ASSESSOR && game.approvedReportCount)) &&
    gameReportSettings[`link_position_${assignment.official_label_col + 1}`];

  const allowedToSeeCVR = gameReportSettings && gameReportSettings.allow_officials_to_checkin;

  const showAssessmentReport =
    allowedToSeeReports &&
    assessmentReportSettings &&
    assessmentReportSettings.allow_assessors_to_submit &&
    (isGameAssessor ||
      (gameOfficialAR &&
        gameOfficialAR.approved &&
        assessmentReportSettings &&
        assessmentReportSettings.allow_assessors_to_submit &&
        assessmentReportSettings.official_auto_release_assessment));
  const isGameComplete =
    moment.utc().format() >
    convertToUtc(`${gameInfo.end_date} ${gameInfo.end_time}`, gameInfo.timezone);
  const assignmentId = assignment.id && assignment.id.toString();

  const lockedAssignments = [];
  const declineableAssignments = [];
  const pastDateAssignments = [];

  function declineAssignment(currentGame) {
    const currentGameAssignment =
      currentGame.assignments_game.length && currentGame.assignments_game[0];
    if (
      currentGameAssignment.status === 'accepted' &&
      moment().isAfter(currentGame.start_date) &&
      moment.utc().format('MM/DD/YYYY') !== moment.utc(currentGame.start_date).format('MM/DD/YYYY')
    ) {
      pastDateAssignments.push(currentGame);
    } else if (
      currentGame &&
      currentGame.game_level &&
      currentGame.game_level.event &&
      currentGame.game_level.event.disable_games_decline_setting.checked &&
      moment.utc().toDate() >=
        moment
          .utc(currentGame.start_date)
          .subtract(
            currentGame.game_level.event.disable_games_decline_setting.days_before_game,
            'days'
          )
          .toDate() &&
      currentGameAssignment.status === 'accepted'
    ) {
      lockedAssignments.push(currentGame);
    } else {
      declineableAssignments.push(currentGame);
    }
  }
  declineAssignment(gameInfo);

  const updateGameAssignmentStatus = (id, status, message, note) => {
    const { start_date, end_date } = startAndEndDates(moment(date));
    if (status === GAME_ASSIGNMENT_STATUSES.accepted) {
      setShowAcceptErrorAssignmentsModal(true);
      const hasError = checkAssignmentConflict({
        userAssignments: userSchedule && userSchedule.game_assignments,
        display_id: game.display_id,
        location: game.location,
        start_date: game.start_date,
        start_time: game.start_time,
        end_date: game.end_date,
        end_time: game.end_time,
        gamesEventId: game.game_level && game.game_level.event_id,
        timezone: game.timezone,
        id: game.id,
        first_name: game.first_name,
        last_name: game.last_name,
        status: game.status
      });
      if (hasError.userGameErrors && hasError.userGameErrors.length) {
        setHasConflictError(hasError.userGameErrors);
      } else {
        const data = { status };
        if (note) data.note = note;
        updateGameAssignments({
          assignmentIds: [id],
          onLoadPage: () => fetchAssignments({ start_date, end_date, payment: true }),
          reloadSelfAssignmentsForDay: userSelfAssignGames,
          selfAssignGamesRequestBody: {
            userId,
            filters: {
              start_date: moment(game.start_at).startOf('day').format(),
              end_date: moment(game.end_at).endOf('day').format(),
              official_self_assign_setting: true,
              eventStatus: true
            },
            supress_event_data: true,
            games_for_date: moment(game.start_at).date(),
            sortingValue: DefaultValues.SORTBY
          },
          data,
          message
        });
      }
    } else {
      const data = { status };
      if (note) data.note = note;
      updateGameAssignments({
        assignmentIds: [id],
        onLoadPage: () => fetchAssignments({ start_date, end_date, payment: true }),
        reloadSelfAssignmentsForDay: userSelfAssignGames,
        selfAssignGamesRequestBody: {
          userId,
          filters: {
            start_date: moment(game.start_at).startOf('day').format(),
            end_date: moment(game.end_at).endOf('day').format(),
            official_self_assign_setting: true,
            eventStatus: true
          },
          supress_event_data: true,
          games_for_date: moment(game.start_at).date(),
          sortingValue: DefaultValues.SORTBY
        },
        data,
        message
      });
    }
  };
  const isGameCancelledOrPostponed =
    game.status === GAMES_STATUSES.CANCELLED || game.status === GAMES_STATUSES.POSTPONED;
  return (
    <AssignmentRow>
      <Info>
        <AssignmentInfoRow>
          <AssignmentInfoCol>
            {assignment.status && (
              <InfoRow>
                <Col xs={12} className="actionButton mobilePaddingZero">
                  <StyledButton
                    label="Accept"
                    onClick={() =>
                      updateGameAssignmentStatus(
                        assignmentId,
                        'accepted',
                        'Successfully accepted assignment'
                      )
                    }
                    disabled={!(assignment.status === 'pending')}
                  >
                    <CheckCircleOutlineIcon style={{ fontSize: '1.3rem' }} />

                    <span>{isGameCancelledOrPostponed ? 'Confirm' : 'Accept'}</span>
                  </StyledButton>
                  {!isGameCancelledOrPostponed &&
                    (!!pastDateAssignments.length || !!lockedAssignments.length) && (
                      <MouseOverPopover
                        transformOriginHorizontal="right"
                        messageBody={
                          pastDateAssignments.length
                            ? `Game with ID
                  ${pastDateAssignments.map(gameAssignment => gameAssignment.display_id).join(', ')}
                   cannot be declined after the game has been played.`
                            : `${lockedAssignments
                                .map(gameAssignment => gameAssignment.display_id)
                                .join(', ')} cannot
                   be declined within ${lockedAssignments.map(
                     gameAssignment =>
                       gameAssignment &&
                       gameAssignment.game_level &&
                       gameAssignment.game_level.event &&
                       gameAssignment.game_level.event.disable_games_decline_setting
                         .days_before_game
                   )} days of the
                   game start date`
                        }
                      >
                        <StyledButton
                          disabled={!declineableAssignments.length}
                          className={classes.Button}
                          margin="0px 15px 0px 0px"
                        >
                          <CancelOutlinedIcon style={{ fontSize: '1.3rem' }} />

                          <span>Decline</span>
                        </StyledButton>
                      </MouseOverPopover>
                    )}

                  {!isGameCancelledOrPostponed && declineableAssignments.length ? (
                    <StyledButton
                      onClick={() => setIsDeclinedClick(true)}
                      margin="0px 15px 0px 0px"
                      disabled={
                        declineableAssignments[0].assignments_game.length &&
                        declineableAssignments[0].assignments_game[0].status === 'declined'
                      }
                    >
                      <CancelOutlinedIcon style={{ fontSize: '1.3rem' }} />

                      <span>Decline</span>
                    </StyledButton>
                  ) : (
                    ' '
                  )}
                </Col>
                {isDeclinedClick && (
                  <ConfirmationPopup
                    title="Decline Confirmation"
                    description="Are you sure you want to decline this assignment?"
                    SubmitBtnText="CONFIRM"
                    cancelButtonText="CANCEL"
                    confirmationPopupAction={isDeclinedClick}
                    setConfirmationPopupAction={setIsDeclinedClick}
                    primaryActionFun={({ note }) =>
                      updateGameAssignmentStatus(
                        assignmentId,
                        'declined',
                        'Successfully declined assignment',
                        note
                      )
                    }
                  />
                )}
              </InfoRow>
            )}

            {!assignment.status ? (
              <PositionRow padding="0px">
                <OpenPosition
                  createGameAssignment={createGameAssignment}
                  setPulledDates={setPulledDates}
                  game={game}
                  user={user}
                  date={date}
                  startAndEndDates={startAndEndDates}
                  fetchAssignments={fetchAssignments}
                  crewMembers={crewMembers}
                  key={game.id}
                />
              </PositionRow>
            ) : (
              <PositionRow padding="20px 0px 0px 0px">
                {game.game_level.labels.map((gameCol, index) => (
                  <AssignedPosition
                    crewMembers={crewMembers}
                    index={index}
                    game={game}
                    userId={userId}
                    key={index}
                  />
                ))}
              </PositionRow>
            )}
            <PositionRow padding="0px">
              <Col className="flexNoWrap">
                <CrewName>
                  <SubInfo className={classes.assignorLabel}>Assigned By :</SubInfo>
                  <ToolTip
                    messageTitle={
                      assignorDetails &&
                      `${assignorDetails.assignor_first_name} ${assignorDetails.assignor_last_name}`
                    }
                    messageBody={assignorDetails && assignorDetails.assignor_phone_number}
                    buttonText="Copy"
                    color={COLORS.lightBlue}
                  >
                    <SubInfo color={COLORS.lightBlue} padding="0px 20px 0px 0px">
                      {' '}
                      {assignorDetails &&
                        `${assignorDetails.assignor_first_name} ${assignorDetails.assignor_last_name}`}
                    </SubInfo>
                  </ToolTip>
                </CrewName>
              </Col>
            </PositionRow>
          </AssignmentInfoCol>
          <AssignmentInfoCol>
            <InfoRow>
              <Col className="pd">
                <StatusIconContainer className={classes.CalendarIcon}>
                  <IconStyle className="material-icons">event</IconStyle>
                </StatusIconContainer>
              </Col>
              <Col xs={10}>
                <Time fontWeight="bold">
                  {moment(game.start_date).format('dddd')}
                  {', '}
                  {moment(game.start_date).format('MMMM')}
                  {` `}
                  {moment(game.start_date).format('DD')} @ {game.start_time} {`(${timezoneAbbr})`}
                </Time>
              </Col>
            </InfoRow>
            <InfoRow>
              <Col className="pd">
                <StatusIconContainer className={classes.FlagIcon}>
                  <IconStyle className="material-icons">flag</IconStyle>
                </StatusIconContainer>
              </Col>
              <Col xs={10}>
                <Time fontWeight="bold">
                  {game && game.game_level && game.game_level.game_level}
                  {` - `}
                  {game && game.game_level && game.game_level.game_type}
                </Time>
                <FieldInfo>
                  <span className="whiteSpace"> Home :</span>
                  <Uppercase wordbreak>{game && game.team_home.name}</Uppercase>
                </FieldInfo>
                <FieldInfo>
                  <span className="whiteSpace"> Away :</span>
                  <Uppercase wordbreak>{game && game.team_visitor.name}</Uppercase>
                </FieldInfo>
              </Col>
            </InfoRow>
            <InfoRow>
              <Col className="pd">
                <StatusIconContainer>
                  <IconStyle className="material-icons">location_on</IconStyle>
                </StatusIconContainer>
              </Col>
              <Col xs={10}>
                <Time>
                  <b>
                    {' '}
                    {game && game.location.name}
                    {game && game.field_name && `, ${game.field_name}`}
                  </b>
                </Time>
                <FieldInfo>
                  {game && game.location.address}
                  {` `}
                  {game && game.location.address_two && `, ${game.location.address_two}, `}
                  {`${game.location.city}, ${game.location.state}, ${game.location.postal_code}`}
                </FieldInfo>
                <FieldInfo>
                  <Direction {...game} />
                </FieldInfo>
              </Col>
            </InfoRow>
          </AssignmentInfoCol>
          <AssignmentInfoCol>
            <InfoRow>
              <Col xs={12} className="paddingZero">
                <FieldInfo>
                  {game && game.game_level && game.game_level.event && game.game_level.event.name}
                  {` `}
                  {` game #`}
                  {addCountToDisplayId(game)}
                  {` `}
                  {calendar_game_status.includes(game.status.toLowerCase()) && (
                    <GameStatus>{game.status}</GameStatus>
                  )}
                </FieldInfo>
              </Col>
            </InfoRow>
            <InfoRow>
              {isGameComplete && (
                <Col xs={12} className="paddingZero">
                  <ReportTab>
                    <Col>
                      <SubInfo fontWeight="400" textAlign="left" color={COLORS.greyish} whiteSpace>
                        Reports :
                      </SubInfo>
                    </Col>
                    <Col xs={9} sm={9} md={8}>
                      {allowedToSeeReportsForGameReport &&
                        allowedToSeeGameReports &&
                        !isGameCancelledOrPostponed &&
                        assignment.status === ASSIGNMENT_STATUS.accepted && (
                          <ReportLink onClick={() => onGamesReportClick(gameInfo)}>Game</ReportLink>
                        )}

                      {allowedForGameOfficialReport &&
                        allowedToSeeGameReports &&
                        !isGameCancelledOrPostponed &&
                        assignment.status === ASSIGNMENT_STATUS.accepted &&
                        !(game && game.gameReportSyncLock) && (
                          <ReportLink onClick={() => onGamesReportClick(gameInfo)}>Game</ReportLink>
                        )}
                      {allowedToSeeReports &&
                        allowedToSeeIR &&
                        gameOfficial &&
                        assignment.status === ASSIGNMENT_STATUS.accepted && (
                          <ReportLink onClick={() => onIncidentReportClick(gameInfo)}>
                            Incident
                          </ReportLink>
                        )}
                      {showAssessmentReport && (
                        <ReportLink
                          onClick={() =>
                            onAssessmentReportClick({
                              eventId: game.game_level && game.game_level.event_id.toString(),
                              gameId: game.display_id,
                              assignmentOfficial: assignment,
                              crewLabel:
                                game.game_level &&
                                game.game_level.labels &&
                                game.game_level.labels[assignment.official_label_col]
                            })
                          }
                        >
                          Assessment
                        </ReportLink>
                      )}
                      {allowedToSeeReports &&
                        allowedToSeeCVR &&
                        !isGameCancelledOrPostponed &&
                        assignment.status === ASSIGNMENT_STATUS.accepted && (
                          <ReportLink onClick={() => onCrewVerificationClick(gameInfo)}>
                            Crew Verification
                          </ReportLink>
                        )}
                    </Col>
                  </ReportTab>
                </Col>
              )}
            </InfoRow>
          </AssignmentInfoCol>
        </AssignmentInfoRow>
      </Info>
      {showAcceptErrorAssignmentsModal && hasConflictError.length && (
        <Modal
          title="Accept Game Assignments"
          onClose={() => setShowAcceptErrorAssignmentsModal(false)}
        >
          <Row>
            <Block xs={10} xsOffset={1}>
              {hasConflictError.map(error => (
                <Content>
                  This game is in conflict with Game {error.display_id}, please decline that game to
                  accept this one or contact your assignor
                </Content>
              ))}
            </Block>
          </Row>
          <Row>
            <Block xs={12} className="last">
              <ButtonContainer>
                <Button
                  label="Close"
                  width="84px"
                  height="32px"
                  primary
                  onClick={() => {
                    setShowAcceptErrorAssignmentsModal(false);
                  }}
                />
              </ButtonContainer>
            </Block>
          </Row>
        </Modal>
      )}
    </AssignmentRow>
  );
};

export default CalenderExpandInfo;
